<template>
	<div class="live-set el-content">
		<com-power-form :form-data="[
			{label:'是否开启物联网',type:'radio',value:'',key:'is_open_web_of_thing',dataType:'string',
				options:[
					{label:'关闭',value:'0'},
					{label:'坤典物联网平台',value:'1'},
				]
			},
		]"></com-power-form>
	</div>
</template>
<script>
import comPowerForm from '@/components/form/com-power-form.vue'
export default{
	components:{
		comPowerForm
	}
}
</script>
<style>
</style>
